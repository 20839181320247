import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--map-customization"
export default class extends Controller {
    infowindows = []

    async connect() {
        await this.waitForInitialization()
        const mapdata = JSON.parse(document.getElementById('map_customization_mapdata').value)
        const pins = JSON.parse(document.getElementById('map_customization_pins').value)
        const map = new google.maps.Map(document.getElementById("map_customization_map"), {
            zoom: 11,
            center: {lat: parseFloat(mapdata.latitude), lng: parseFloat(mapdata.longitude)},
        });

        new google.maps.Marker({
            position: {lat: parseFloat(mapdata.latitude), lng: parseFloat(mapdata.longitude)},
            map: map,
            icon: {
                url: mapdata.icon,
                scaledSize: new google.maps.Size(40, 40)
            }
        });

        const self = this

        pins.forEach((pin) => {
            const marker = new google.maps.Marker({
                position: {lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude)},
                map: map,
                icon: {
                    url: pin.icon,
                    scaledSize: new google.maps.Size(40, 40)
                }
            });

            const infowindow = new google.maps.InfoWindow({
                content: `${pin.name}<br>${pin.address}<br><a href="${pin.link}">詳細</a>`,
                ariaLabel: pin.name,
            });

            marker.addListener("click", () => {
                self.infowindows.forEach((infowindow) => {
                    infowindow.close()
                })

                infowindow.open({
                    anchor: marker,
                    map,
                });

                self.infowindows.push(infowindow)
            });
        })
    }

    async waitForInitialization() {
        return new Promise((resolve, reject) => {
            const id = setInterval(() => {
                if (google != undefined) {
                    clearInterval(id)
                    resolve()
                }
            }, 100)
        })
    }
}
