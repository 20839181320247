import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--map-customization-form"
export default class extends Controller {
    geocoder = null

    connect() {
        const self = this
        const addressEl = document.getElementById('map_customization_address')

        addressEl.onchange = (event) => {
            if (self.geocoder === null) {
                self.geocoder = new google.maps.Geocoder
            }

            const prefecture = Array.from(document.querySelectorAll('#map_customization_prefecture_id option')).filter((option) => option.selected).map((option) => option.innerText).pop()

            self.geocoder.geocode({address: `${prefecture} ${addressEl.value}`}).then((value) => {
                document.getElementById('map_customization_latitude').value = value.results[0].geometry.location.lat()
                document.getElementById('map_customization_longitude').value = value.results[0].geometry.location.lng()
            })
        }
    }
}
