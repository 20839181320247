import {Controller} from "@hotwired/stimulus"
import {concatMap, fromEvent, Observable, Subscription} from "rxjs";
import EventEmitter from "events";

// Connects to data-controller="admin--batch-action"
export default class extends Controller {
    static targets = ["actions", "selected", "action"]
    static outlets = ["admin--selectable-column"]
    eventEmitter = new EventEmitter()
    subscriptions = new Subscription()

    connect() {
        this.subscriptions.add(fromEvent(this.eventEmitter, 'updateView').pipe(concatMap((event) => {
            return this.createUpdateViewObservable()
        })).subscribe())

        this.actionTargets.forEach((actionTarget) => {
            const actionTargetForm = actionTarget.closest('form')

            actionTargetForm.addEventListener('submit', (event) => {
                const selected = this.adminSelectableColumnOutlet.checkTargetsBySelected()

                Array.from(actionTargetForm.querySelectorAll('input[name="ids[]"]')).forEach((idsInputEl) => {
                    idsInputEl.remove()
                })

                selected.forEach((selectedEl) => {
                    const inputTag = document.createElement('input')
                    inputTag.setAttribute('type', 'hidden')
                    inputTag.setAttribute('name', 'ids[]')
                    inputTag.setAttribute('value', selectedEl.value)
                    actionTargetForm.appendChild(inputTag)
                })
            })
        })
    }

    adminSelectableColumnOutletConnected() {
        this.updateView()
    }

    disconnect() {
        super.disconnect()
        this.subscriptions.unsubscribe()
    }

    updateView() {
        this.eventEmitter.emit('updateView')
    }

    createUpdateViewObservable() {
        const self = this

        return new Observable((subscriber) => {
            if (self.hasAdminSelectableColumnOutlet && self.hasActionsTarget) {
                const selected = self.adminSelectableColumnOutlet.checkTargetsBySelected()
                self.selectedTargets.forEach((selectedTarget) => {
                    selectedTarget.innerHTML = `${selected.length}`
                })

                if (selected.length > 0) {
                    self.actionsTarget.style.opacity = 0
                    self.actionsTarget.style.display = null
                    self.actionsTarget.style.transition = 'opacity 300ms'
                    setTimeout(() => {
                        self.actionsTarget.style.opacity = null
                        setTimeout(() => {
                            self.actionsTarget.style.transition = null
                            subscriber.next()
                            subscriber.complete()
                        }, 300)
                    }, 0)
                } else {
                    self.actionsTarget.style.transition = null
                    self.actionsTarget.style.display = 'none'
                    self.actionsTarget.style.opacity = 0
                    subscriber.next()
                    subscriber.complete()
                }
            } else {
                subscriber.next()
                subscriber.complete()
            }
        })
    }

    clearSelection(event) {
        event.preventDefault()

        if (this.hasAdminSelectableColumnOutlet) {
            this.adminSelectableColumnOutlet.clearSelection()
            this.updateView()
        }

        return false
    }
}
