import {Controller} from "@hotwired/stimulus"
import {createPopper} from '@popperjs/core';
import { v4 as uuidv4 } from 'uuid';

export default class extends Controller {
    static targets = ["popover", "anchor", "instance", "backdrop", "contents", "scroll"]
    static values = {placement: String, anchorId: String}
    anchorId = uuidv4()
    popper = null
    onScrollListener = null
    onBeforeVisitListener = null

    connect() {
        super.connect()

        if (this.hasInstanceTarget) {
            this._showPopover()
        } else {
            this.popoverTarget.style.display = "none"
            this.anchorTarget.setAttributeNS(null, "data-anchor-id", this.anchorId)
        }
    }

    show(e) {
        e.preventDefault()
        this._createPopover()
    }

    hide(e) {
        if (e) e.preventDefault()
        this._hidePopover()
    }

    _createPopover() {
        const backdrop = document.createElement("div")
        backdrop.className = "fixed w-full h-full bg-black opacity-0"
        backdrop.style.zIndex = 10001
        backdrop.setAttributeNS(null, "data-action", `click->${this.identifier}#hide`)
        backdrop.setAttributeNS(null, `data-${this.identifier}-target`, "backdrop")

        const contents = this.popoverTarget.cloneNode(true)
        contents.style.display = ""

        const contentsWrapper = document.createElement("div")
        contentsWrapper.className = "opacity-0"
        contentsWrapper.style.zIndex = 10002
        contentsWrapper.setAttributeNS(null, `data-${this.identifier}-target`, "contents")
        contentsWrapper.insertAdjacentHTML('beforeend', contents.outerHTML)

        const popover = document.createElement("div")
        popover.className = "fixed top-0 left-0 w-full min-h-screen h-full"
        popover.style.zIndex = 10000
        popover.setAttributeNS(null, "data-controller", this.identifier)
        popover.setAttributeNS(null, `data-${this.identifier}-target`, "instance")
        popover.setAttributeNS(null, `data-${this.identifier}-anchor-id-value`, this.anchorId)
        popover.setAttributeNS(null, `data-${this.identifier}-placement-value`, this.placementValue)
        popover.appendChild(backdrop)
        popover.appendChild(contentsWrapper)

        document.querySelector('body').appendChild(popover)
    }

    _showPopover() {
        this.popper = createPopper(document.querySelector(`*[data-anchor-id="${this.anchorIdValue}"]`), this.contentsTarget, {placement: this.placementValue})
        this.contentsTarget.style.transition = "opacity 140ms"

        setTimeout(() => {
            this.contentsTarget.style.opacity = 1
        }, 0)

        this.instanceTarget[this.identifier] = this
        this._startListenOnBeforeVisit()
        this._startListenOnScroll()
    }

    _hidePopover() {
        this._stopListenOnBeforeVisit()
        this._stopListenOnScroll()
        this.popper.destroy()
        this.instanceTarget.remove()
    }

    _startListenOnScroll() {
        this.onScrollListener = () => this._hidePopover()
        window.addEventListener("scroll", this.onScrollListener, false)
    }

    _stopListenOnScroll() {
        window.removeEventListener("scroll", this.onScrollListener, false)
    }

    _startListenOnBeforeVisit() {
        this.onBeforeVisitListener = () => this._hidePopover()
        document.documentElement.addEventListener("turbo:before-visit", this.onBeforeVisitListener)
    }

    _stopListenOnBeforeVisit() {
        document.documentElement.removeEventListener("turbo:before-visit", this.onBeforeVisitListener)
    }
}
