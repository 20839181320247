import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["overlay", "deleteButton", "revertButton", "destroyField"]

    connect() {
        super.connect()
        this.overlayTarget.style.display = "none"
        this.revertButtonTarget.style.display = "none"
    }

    remove(e) {
        e.currentTarget.blur()
        e.preventDefault()
        this.overlayTarget.style.display = null
        this.deleteButtonTarget.style.opacity = 0
        this.revertButtonTarget.style.display = null
        this.destroyFieldTarget.value = "true"
    }

    revert(e) {
        e.preventDefault()
        this.overlayTarget.style.display = "none"
        this.deleteButtonTarget.style.opacity = null
        this.revertButtonTarget.style.display = "none"
        this.destroyFieldTarget.value = "false"
    }
}
