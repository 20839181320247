import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['template', 'items']

    connect() {
        Array.from(['input', 'textarea', 'select']).forEach((tagName) => {
            Array.from(this.templateTarget.getElementsByTagName(tagName)).forEach((element) => {
                element.disabled = true
            })
        })
    }

    add(e) {
        e.preventDefault()
        const index = new Date().getTime().toString()
        this.itemsTarget.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML.replace(/NEW_RECORD/g, index))
        Array.from(document.querySelectorAll(`*[name*="[${index}]"]`)).forEach((element) => {
            element.disabled = false
        })
    }
}
