import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['input']

    select(e) {
        e.preventDefault()
        this.inputTarget.name = `q[${e.currentTarget.value}]`
    }
}
