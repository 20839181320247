import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["label", "overlay", "removeButton", "revertButton", "purgeInput"]

    connect() {
        super.connect()

        if (this.hasPurgeInputTarget) {
            if (this.purgeInputTarget.value === "true") {
                this._removeFile()
            } else {
                this._keepFile()
            }
        }
    }

    change(e) {
        this.labelTarget.innerText = e.target.files[0].name
    }

    remove(e) {
        e.preventDefault()
        this._removeFile()
    }

    revert(e) {
        e.preventDefault()
        this._keepFile()
    }

    _removeFile() {
        this.overlayTarget.style.display = null
        this.removeButtonTarget.style.display = "none"
        this.revertButtonTarget.style.display = null
        this.purgeInputTarget.value = "true"
    }

    _keepFile() {
        this.overlayTarget.style.display = "none"
        this.removeButtonTarget.style.display = null
        this.revertButtonTarget.style.display = "none"
        this.purgeInputTarget.value = "false"
    }
}
