import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    resize(event) {
        event.target.style.height = "auto"
        event.target.style.height = (
            event.target.scrollHeight +
            this._getBorderHeight(getComputedStyle(event.target))
        ) + "px"
    }

    _getBorderHeight(computedStyle) {
        return [
            computedStyle.borderTopWidth,
            computedStyle.borderBottomWidth,
        ].map((width) => {
            return parseInt(width.replaceAll(/A-z/g, ""))
        }).reduce((total, value) => {
            return total + value
        }, 0)
    }
}
