import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['chevron', 'body']

    connect() {
        super.connect()
        this.bodyTarget.style.height = 0
    }

    toggle(e) {
        e.preventDefault()

        if (this.chevronTarget.classList.contains('rotate-90')) {
            this._hide()
        } else {
            this._open()
        }
    }

    _open() {
        this.chevronTarget.style.transition = 'transform 300ms ease-out'
        this.bodyTarget.style.transition = 'height 300ms ease-out'

        setTimeout(() => {
            this.chevronTarget.classList.add('rotate-90')
            this.bodyTarget.style.height = `${this.bodyTarget.scrollHeight}px`
        }, 0);
    }

    _hide() {
        this.chevronTarget.classList.remove('rotate-90')
        this.bodyTarget.style.height = 0

        setTimeout(() => {
            this.chevronTarget.style.transition = null
            this.bodyTarget.style.transition = null
        }, 300)
    }
}
