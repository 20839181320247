import {Controller} from "@hotwired/stimulus"
import {Datepicker} from 'vanillajs-datepicker';
import ja from 'vanillajs-datepicker/locales/ja';

Object.assign(Datepicker.locales, ja);

export default class extends Controller {
    connect() {
        const datepicker = new Datepicker(this.element, {
            autohide: true,
            language: 'ja'
        })
    }
}
