import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--filter-condition"
export default class extends Controller {
    connect() {
    }

    clear(event) {
        event.preventDefault()

        Turbo.visit(window.location.href
            .replaceAll(/q%5B[^&]*&?/g, "")
            .replaceAll(/page=[^&]*&?/g, ""))
    }

    removeFilter(event) {
        event.preventDefault()
        const url = new URL(location.href)
        const searchParams = url.searchParams
        const deleteNames = []

        switch (event.params.filterType) {
            case 'string':
            case 'numeric':
                const opName = `q[${event.params.id}_op]`
                const queryName = `q[${searchParams.get(opName)}]`
                deleteNames.push(opName, queryName)
                break
            case 'date_range':
                deleteNames.push(`q[${event.params.id}_gteq]`, `q[${event.params.id}_lteq_eod]`)
                break
            case 'select':
                deleteNames.push(`q[${event.params.id}_eq]`)
                break
            case 'check_boxes':
                deleteNames.push(`q[${event.params.id}_in][]`)
                break
        }

        deleteNames.forEach((name) => {
            searchParams.delete(name)
        })

        url.search = searchParams.toString()
        console.log(event.params, deleteNames, searchParams, url.toString())
        Turbo.visit(url.toString())
    }
}
