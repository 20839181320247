import {Controller} from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

// Connects to data-controller="admin--chart-js"
export default class extends Controller {
    static targets = ["canvas"]
    static values = {type: String, data: Object, options: Object}

    connect() {
        new Chart(this.canvasTarget, {
            type: this.typeValue,
            data: this.dataValue,
            options: this.optionsValue
        })
    }
}
