import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--selectable-column"
export default class extends Controller {
    static targets = ["checkAll", "check"]

    connect() {
        if (this.hasCheckAllTarget && this.hasCheckTarget) {
            this.checkAllTarget.addEventListener('change', (event) => {
                this.checkTargets.forEach((checkTarget) => {
                    checkTarget.checked = event.target.checked
                })

                this.checkAllTarget.indeterminate = false
                this.dispatchChangeEvent()
            })

            this.checkTargets.forEach((checkTarget) => {
                checkTarget.addEventListener('change', (event) => {
                    const selected = this.checkTargetsBySelected()
                    this.checkAllTarget.checked = selected.length === this.checkTargets.length
                    this.checkAllTarget.indeterminate = selected.length > 0 && selected.length !== this.checkTargets.length
                    this.dispatchChangeEvent()
                })
            })
        }
    }

    checkTargetsBySelected() {
        return this.checkTargets.filter((checkTarget) => checkTarget.checked === true)
    }

    dispatchChangeEvent() {
        this.dispatch('change', {
            detail: {
                checkTargets: this.checkTargets,
                checkTargetsBySelected: this.checkTargetsBySelected()
            }
        })
    }

    clearSelection() {
        this.checkTargets.forEach((checkTarget) => {
            checkTarget.checked = false
        })

        this.checkAllTarget.checked = false
        this.checkAllTarget.indeterminate = false
        this.dispatchChangeEvent()
    }
}
