import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--alternative-element"
export default class extends Controller {
    static targets = ["alt"]
    static values = {statement: "full" | "part", scroller: String, container: String}

    connect() {
        this.scroller = this.scrollerValue ? document.querySelector(this.scrollerValue) : window

        if (this.containerValue) {
            const self = this

            this.containerResizeObserver = new ResizeObserver(() => {
                self.updateView()
            })

            this.containerResizeObserver.observe(document.querySelector(this.containerValue))
        }

        this.scroller.addEventListener('scroll', this.scrollerScrollEventListener = () => {
            this.updateView()
        })

        this.updateView()
    }

    disconnect() {
        super.disconnect();

        if (this.containerResizeObserver) {
            this.containerResizeObserver.disconnect()
        }

        this.scroller.removeEventListener('scroll', this.scrollerScrollEventListener, false)
    }

    updateView() {
        const rect = this.element.getBoundingClientRect()
        const points = [
            {x: rect.left, y: rect.top},
            {x: rect.left, y: rect.bottom},
            {x: rect.right, y: rect.top},
            {x: rect.right, y: rect.bottom}
        ]

        points.map((point) => {
            document.elementFromPoint(point.x, point.y)
        })

        let verticalPosition = null

        if (this.statementValue === 'full') {
            if (0 < rect.top && rect.bottom < window.innerHeight) {
                this.altTarget.style.display = 'none'
            } else {
                this.altTarget.style.display = null

                if (0 > rect.top) {
                    verticalPosition = 'top'
                } else {
                    verticalPosition = 'bottom'
                }
            }
        } else {
            if (0 < rect.bottom && rect.top < window.innerHeight) {
                this.altTarget.style.display = 'none'
            } else {
                this.altTarget.style.display = null

                if (0 > rect.bottom) {
                    verticalPosition = 'top'
                } else {
                    verticalPosition = 'bottom'
                }
            }
        }

        if (this.containerValue) {
            const containerRect = document.querySelector(this.containerValue).getBoundingClientRect()
            const altTargetRect = this.altTarget.getBoundingClientRect()
            this.altTarget.style.position = 'fixed'
            this.altTarget.style.left = `${containerRect.left}px`
            this.altTarget.style.right = `${window.innerWidth - containerRect.right}px`

            if (verticalPosition === 'top') {
                if (containerRect.bottom < altTargetRect.height) {
                    this.altTarget.style.top = null
                    this.altTarget.style.bottom = `${Math.max(window.innerHeight - containerRect.bottom, 0)}px`
                } else {
                    this.altTarget.style.top = `${Math.max(containerRect.top, 0)}px`
                    this.altTarget.style.bottom = null
                }
            } else {
                if ((window.innerHeight - containerRect.top) < altTargetRect.height) {
                    this.altTarget.style.top = `${Math.max(containerRect.top, 0)}px`
                    this.altTarget.style.bottom = null
                } else {
                    this.altTarget.style.top = null
                    this.altTarget.style.bottom = `${Math.max(window.innerHeight - containerRect.bottom, 0)}px`
                }
            }
        }
    }
}
