import {Controller} from "@hotwired/stimulus"
import Choices from "choices.js"

// Connects to data-controller="admin--choices-js"
export default class extends Controller {
    connect() {
        const choices = new Choices(this.element, {
            shouldSort: false,
            loadingText: '読み込み中',
            noResultsText: '該当するデータはありません',
            noChoicesText: '選択可能なデータがありません',
            itemSelectText: '',
            classNames: {
                containerOuter: 'admin choices'
            },
        })
    }
}
