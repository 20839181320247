import {Controller} from "@hotwired/stimulus"
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

export default class extends Controller {
    static targets = ["modal", "instance", "backdrop", "contents", "scroll"]
    static values = {animation: String}
    onBeforeVisitListener = null

    connect() {
        super.connect()

        if (this.hasInstanceTarget) {
            this._showModal()
        } else if (this.hasModalTarget) {
            this.element.dataset.template = this.modalTarget.outerHTML
            this.modalTarget.remove()
        }
    }

    show(e) {
        e.preventDefault()
        this._createModal()
    }

    hide(e) {
        if (e) e.preventDefault()
        this._hideModal()
    }

    _createModal() {
        const backdrop = document.createElement("div")
        backdrop.className = "fixed w-full h-full bg-black opacity-0"
        backdrop.style.zIndex = 10001
        backdrop.setAttributeNS(null, "data-action", `click->${this.identifier}#hide`)
        backdrop.setAttributeNS(null, `data-${this.identifier}-target`, "backdrop")

        const contentsWrapper = document.createElement("div")
        contentsWrapper.className = "fixed w-full h-full opacity-0 pointer-events-none"
        contentsWrapper.style.zIndex = 10002
        contentsWrapper.setAttributeNS(null, `data-${this.identifier}-target`, "contents")
        contentsWrapper.insertAdjacentHTML('beforeend', this.element.dataset.template)

        const modal = document.createElement("div")
        modal.className = "fixed top-0 left-0 w-full min-h-screen h-full"
        modal.style.zIndex = 10000
        modal.setAttributeNS(null, "data-controller", this.identifier)
        modal.setAttributeNS(null, `data-${this.identifier}-target`, "instance")
        modal.setAttributeNS(null, `data-${this.identifier}-animation-value`, this.animationValue)
        modal.appendChild(backdrop)
        modal.appendChild(contentsWrapper)

        document.querySelector('body').appendChild(modal)
    }

    _showModal() {
        this.backdropTarget.style.transition = "opacity 300ms"
        this.contentsTarget.style.transition = "opacity 300ms"

        if (this.animationValue) {
            let animation = this.animationValue.split(" ")

            switch (animation[0]) {
                case "down":
                    var distance = animation[1] ? animation[1] : "3%"
                    var dulation = animation[2] ? animation[2] : "300ms"
                    var timing = animation[3] ? animation[3] : "ease-out"
                    this.contentsTarget.style.transition = [this.contentsTarget.style.transition, `transform ${dulation} ${timing}`].join(",")
                    this.contentsTarget.style.transform = `translate(0, -${distance})`
                    break
                case "up":
                    var distance = animation[1] ? animation[1] : "3%"
                    var dulation = animation[2] ? animation[2] : "300ms"
                    var timing = animation[3] ? animation[3] : "ease-out"
                    this.contentsTarget.style.transition = [this.contentsTarget.style.transition, `transform ${dulation} ${timing}`].join(",")
                    this.contentsTarget.style.transform = `translate(0, ${distance})`
                    break
                case "left":
                    var distance = animation[1] ? animation[1] : "3%"
                    var dulation = animation[2] ? animation[2] : "300ms"
                    var timing = animation[3] ? animation[3] : "ease-out"
                    this.contentsTarget.style.transition = [this.contentsTarget.style.transition, `transform ${dulation} ${timing}`].join(",")
                    this.contentsTarget.style.transform = `translate(${distance}, 0)`
                    break
                default:
                    break
            }
        }

        setTimeout(() => {
            this.backdropTarget.style.opacity = 0.3
            this.contentsTarget.style.opacity = 1

            if (this.animationValue) {
                let animation = this.animationValue.split(" ")

                switch (animation[0]) {
                    case "down":
                    case "up":
                    case "left":
                    case "right":
                        this.contentsTarget.style.transform = "translate(0, 0)"
                        break
                    default:
                        break
                }
            }
        }, 0)

        this.instanceTarget[this.identifier] = this
        disableBodyScroll(this._scrollElement())
        this._startListenOnBeforeVisit()
    }

    _hideModal() {
        this._stopListenOnBeforeVisit()
        enableBodyScroll(this._scrollElement())
        this.instanceTarget.remove()
    }

    _scrollElement() {
        return this.hasScrollTarget ? this.scrollTarget : this.instanceTarget
    }

    _startListenOnBeforeVisit() {
        this.onBeforeVisitListener = () => this._hideModal()
        document.documentElement.addEventListener("turbo:before-visit", this.onBeforeVisitListener)
    }

    _stopListenOnBeforeVisit() {
        document.documentElement.removeEventListener("turbo:before-visit", this.onBeforeVisitListener)
    }
}
