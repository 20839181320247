import {Controller} from "@hotwired/stimulus"
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

export default class extends Controller {
    static targets = ['drawer', 'backdrop', "scroll"]
    onBeforeVisit

    open(e) {
        e.preventDefault()
        this.drawerTarget.style.transition = 'margin-left 200ms ease-in-out'
        this.drawerTarget.style.marginLeft = 0
        this.backdropTarget.style.transition = 'opacity 200ms ease-in-out'
        this.backdropTarget.style.pointerEvents = 'auto'
        this.backdropTarget.style.opacity = 0.3
        this.onBeforeVisit = () => this._closeNavigationDrawer()
        document.documentElement.addEventListener('turbo:before-visit', this.onBeforeVisit)
        disableBodyScroll(this._scrollElement())
    }

    close(e) {
        e.preventDefault()
        this._closeNavigationDrawer()
    }

    _closeNavigationDrawer() {
        enableBodyScroll(this._scrollElement())

        this.drawerTarget.style.marginLeft = null

        setTimeout(() => {
            this.drawerTarget.style.transition = null
        }, 300)

        this.backdropTarget.style.pointerEvents = null
        this.backdropTarget.style.opacity = null

        setTimeout(() => {
            this.backdropTarget.style.transition = null
        }, 300)
    }

    _scrollElement() {
        return this.hasScrollTarget ? this.scrollTarget : this.drawerTarget
    }
}
