import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    sort(e) {
        e.preventDefault()
        const newOrder = e.currentTarget.querySelectorAll("option")[e.currentTarget.selectedIndex].value

        if (newOrder) {
            window.location.search = window.location.search.replace(/q%5Bs%5D=[^&]*&?/, "") + `&q%5Bs%5D=${newOrder}`
        } else {
            window.location.search = window.location.search.replace(/q%5Bs%5D=[^&]*&?/, "")
        }
    }
}
