import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["overlay", "deleteButton", "revertButton"]

    connect() {
        super.connect()
        this.overlayTarget.style.display = "none"
        this.revertButtonTarget.style.display = "none"
    }

    remove(e) {
        e.currentTarget.blur()
        e.preventDefault()
        this.overlayTarget.style.display = null
        this.deleteButtonTarget.style.opacity = 0
        this.revertButtonTarget.style.display = null

        Array.from(["input", "textarea", "select"]).forEach((tagName) => {
            Array.from(this.element.getElementsByTagName(tagName)).forEach((element) => {
                element.disabled = true
            })
        })
    }

    revert(e) {
        e.preventDefault()
        this.overlayTarget.style.display = "none"
        this.deleteButtonTarget.style.opacity = null
        this.revertButtonTarget.style.display = "none"

        Array.from(["input", "textarea", "select"]).forEach((tagName) => {
            Array.from(this.element.getElementsByTagName(tagName)).forEach((element) => {
                element.disabled = false
            })
        })
    }
}
