import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--batch-action-dispatcher"
export default class extends Controller {
    static outlets = ["admin--batch-action"]

    connect() {
    }

    clearSelection(event) {
        event.preventDefault()
        this.adminBatchActionOutlets.forEach((outlet) => outlet.clearSelection(event))
        return false
    }

    submitAction(event) {
        event.preventDefault()

        this.adminBatchActionOutlets.forEach((outlet) => {
            Array.from(outlet.element.querySelectorAll(event.params.action)).forEach((action) => {
                if (outlet.actionTargets.includes(action)) {
                    action.click()
                }
            })
        })

        return false
    }
}
