import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['dialog', 'form']

    submit(e) {
        e.preventDefault()

        Array.from(['input', 'textarea', 'select']).forEach((tagName) => {
            Array.from(this.element.getElementsByTagName(tagName)).forEach((element) => {
                if (element.value === "") {
                    element.disabled = true

                    var nodes = []

                    for (var xpath = document.evaluate("..//select", element, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE), node = xpath.iterateNext(); node != null; node = xpath.iterateNext()) {
                        nodes.push(node)
                    }

                    nodes.forEach((node) => {
                        node.disabled = true
                    })
                }
            })
        })

        this.formTarget.requestSubmit()
    }

    clear(e) {
        e.preventDefault()

        Turbo.visit(window.location.href
            .replaceAll(/q%5B[^&]*&?/g, "")
            .replaceAll(/page=[^&]*&?/g, ""))
    }
}
